  .checkoutPage {
    padding-top: 10rem;
  } 
  
  .checkoutPage h2 {
    text-align: center;
    color: rgb(4, 166, 4);
  }
  .checkoutForm, .testCardsContainer {
    width: 29em;
    margin: auto;
  }

  .form-row {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 2px;
    font-family: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif';
    font-size: 17px;
  }
  
  #cardElement {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: white !important;
  }

  .cardInput {
    padding: 8px 11px;
    font-size: 14px;
    outline: none;
    width: 100%;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white !important;
    font-family: sans-serif;
  }
  
  button[type='submit'] {
    background-color: #1c64f2;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    width: 100%;
    font-family: sans-serif;
  }
  
  button[type='submit']:hover {
    background-color: #0a3ba3;
  }
  
  button[type='submit']:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .card-error {
    color: rgb(236, 12, 12);
    font-size: smaller;
  }

  .InputContainer {
    color: #fff;
  }

  #testCardInfo tbody tr td span {
    display: inline-block;
    margin-right: 8px;
  }

.navbar-brand {
    color: white;
}

.navbar-brand.dark {
        color: white;
    }

.logo-container {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
}

.logo-image-container {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
}

.logo-image {
    height: '60px';
    width: 'auto';
}

.logo-text {
    font-family: 'Chango';
    font-size: '30px';
    margin-left: '50px';
    font-style: 'italic';
}
